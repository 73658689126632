// Mixins
@mixin card-shadow {
  box-shadow: 0 1px 3px rgba($kaizan-black, 0.05);
  transition: box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 2px 4px rgba($kaizan-black, 0.08);
  }
}

// Common transitions and animations
@mixin transition-standard {
  transition: all 0.2s ease;
}

// Activity Feed Structure
.activity-feed {
    position: relative;
    padding-top: 0.5rem;

    &::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 3rem;
        bottom: 0;
        width: 2px;
        background: $kaizan-lightgrey;
    }
}

.activity-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.activity-item {
    position: relative;
    padding-left: 35px;
    margin-bottom: 0.5rem;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    &:not(:last-child) {
        &::after {
            content: '';
            position: absolute;
            left: 35px;
            right: 0;
            bottom: -0.25rem;
            height: 1px;
            background: rgba($kaizan-lightgrey, 0.5);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $kaizan-yellow;
        border: 2px solid $indigo;
        transform: translateY(-50%);
    }
}

.activity-date {
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: $kaizan-darkgrey;
    font-size: 0.9rem;
    padding: 0.35rem 0.75rem;
    position: relative;
    z-index: 1;
    background: $white;
    display: inline-block;
    border-radius: 1.5rem;
    box-shadow: 0 2px 4px rgba($kaizan-black, 0.05);
    border: 1px solid $kaizan-lightgrey;
    
    i, .material-symbols-outlined {
        color: $indigo;
        font-size: 18px;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
}

// Activity Content
.activity-content {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.75rem;
    margin-left: 0.25rem;
    @include transition-standard;
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent rgba($indigo, 0.3);
        opacity: 0;
        transition: opacity 0.2s ease;
    }
}

// Activity Card Link
.activity-card-link {
    display: block;
    text-decoration: none;
    color: inherit;
    
    &:hover {
        text-decoration: none;
        color: inherit;
        
        .activity-content {
            background-color: $white;
            border: 1px solid $indigo;
            border-radius: 0.5rem;
            box-shadow: 0 3px 8px rgba($kaizan-black, 0.1);
            transform: translateY(-2px);
            
            &::after {
                opacity: 1;
            }
        }
        
        .meeting-title {
            color: $indigo;
        }
        
        .participant-tags, .client-tags {
            .badge {
                background-color: rgba($indigo, 0.08) !important;
            }
        }
    }
    
    &:focus {
        outline: none;
        
        .activity-content {
            background-color: $white;
            border: 1px solid $indigo;
            border-radius: 0.5rem;
            box-shadow: 0 0 0 3px rgba($indigo, 0.2);
        }
    }
}

// Client Section
.clients-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.client-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.35rem;

    .badge {
        font-weight: 500;
        padding: 0.25rem 0.5rem;
        background-color: rgba($kaizan-lightgrey, 0.2) !important;
        border: none;
        font-size: 0.8rem;
        border-radius: 0.35rem;
        display: flex;
        align-items: center;
        gap: 0.35rem;
    }
}

.client-icon-small {
    width: 14px;
    height: 14px;
    object-fit: contain;
    border-radius: 3px;
    margin-right: 0.2rem;
    box-shadow: 0 1px 2px rgba($kaizan-black, 0.1);
}

.client-icon-small-fallback {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $indigo;
    color: $white;
    border-radius: 50%;
    font-size: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    flex-shrink: 0;
    margin-right: 0.2rem;
}

.client-badge {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.client-name {
    font-size: 0.8rem;
    color: $kaizan-darkgrey;
    line-height: 1.2;
    font-weight: 500;
    word-wrap: break-word;
}

// Meeting Section
.meeting-section {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
}

.meeting-header {
    font-size: 0.95rem;
    margin-bottom: 0.25rem;
}

.meeting-actions {
    margin-bottom: 0.5rem;
}

.view-details-btn {
    font-size: 0.7rem;
    padding: 0.15rem 0.5rem;
    border-radius: 0.35rem;
    border-color: rgba($indigo, 0.3);
    color: $indigo;
    
    &:hover {
        background-color: rgba($indigo, 0.1);
        border-color: $indigo;
    }
    
    i {
        font-size: 0.65rem;
    }
}

.meeting-time {
    font-weight: 500;
    font-size: 0.7rem;
    background-color: rgba($indigo, 0.08);
    color: $indigo;
    padding: 0.15rem 0.5rem;
    border-radius: 0.75rem;
    display: inline-flex;
    align-items: center;
    border: none;
    white-space: nowrap;
    
    .material-symbols-outlined {
        font-size: 14px;
    }
}

.meeting-separator {
    width: 1px;
    height: 1.25rem;
    background-color: $kaizan-lightgrey;
    margin: 0;
}

.meeting-title {
    font-weight: 500;
    flex: 1;
    line-height: 1.2;
    transition: color 0.2s ease;

    a {
        color: $kaizan-black;
        transition: all 0.2s ease;

        &:hover {
            color: $indigo;
            text-decoration: underline !important;
        }
    }
}

.summary-content {
    font-size: 0.75rem;
    line-height: 1.3;
    color: $kaizan-darkgrey;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0.5rem;
}

// Participants Section
.participants-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.participant-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    .badge {
        font-weight: 500;
        padding: 0.15rem 0.4rem;
        background-color: rgba($kaizan-lightgrey, 0.2) !important;
        border: none;
        font-size: 0.7rem;
        border-radius: 0.35rem;
        margin-bottom: 0.25rem;
        transition: all 0.2s ease;
        pointer-events: none;
        
        &:hover {
            background-color: rgba($indigo, 0.05) !important;
            border-color: rgba($indigo, 0.2);
            transform: translateY(-1px);
        }
        
        i {
            font-size: 0.75rem;
            opacity: 0.7;
        }
    }
}

// Action Items Section
.action-items-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.action-items-card {
    background: $white;
    border-radius: 0.75rem;
    padding: 0.75rem 1rem;
    border: 1px solid $kaizan-lightgrey;
    transition: all 0.2s ease;
    
    &:hover {
        border-color: rgba($indigo, 0.3);
        box-shadow: 0 3px 6px rgba($kaizan-black, 0.08);
    }
}

.action-items-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: 0.6rem;
        font-size: 0.85rem;
        color: $kaizan-darkgrey;
        line-height: 1.4;

        &::before {
            content: '✓';
            position: absolute;
            left: 0.4rem;
            color: $indigo;
            font-weight: bold;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.action-items-compact {
    font-size: 0.75rem;
    line-height: 1.3;
    color: $kaizan-darkgrey;
}

.action-item-compact {
    margin-bottom: 0.25rem;
    padding-left: 1.5rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &::before {
        content: 'check_circle';
        font-family: 'Material Symbols Outlined';
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        color: $indigo;
        font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 20;
    }
}

.action-item-more {
    font-size: 0.7rem;
    color: $indigo;
    margin-top: 0.25rem;
}

.more-items-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: $indigo;
    text-decoration: none;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid $kaizan-lightgrey;
    transition: all 0.2s ease;

    &:hover {
        color: darken($indigo, 10%);
        background-color: rgba($indigo, 0.05);
        border-radius: 0 0 0.5rem 0.5rem;
    }
}

// Section Labels
.section-label {
    font-size: 0.8rem;
    font-weight: 600;
    color: $indigo;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    
    i {
        font-size: 0.9rem;
    }
}

.section-label-compact {
    font-size: 0.7rem;
    font-weight: 600;
    color: $kaizan-midgrey;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    
    i, .material-symbols-outlined {
        font-size: 16px;
        color: $indigo;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
    }
}

// Footer
.activity-footer {
    margin-top: 1rem;
    padding-top: 0.75rem;
    border-top: 1px solid $kaizan-lightgrey;
    display: flex;
    justify-content: flex-end;
    
    .btn {
        font-size: 0.8rem;
        padding: 0.25rem 0.75rem;
        border-radius: 0.5rem;
    }
}

// Material Icons
.material-symbols-outlined {
    font-size: 16px;
    line-height: 1;
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
    vertical-align: middle;
    display: inline-flex;
}

// Responsive Styles
@media (max-width: 767.98px) {
    .activity-feed {
        &::before {
            display: none;
        }
    }

    .activity-item {
        &::before {
            display: none;
        }
        padding-left: 0;
        margin-bottom: 1.5rem;
    }

    .activity-date {
        padding-left: 0;
        margin-bottom: 1rem;
    }

    .activity-content {
        padding: 0.75rem 0.5rem;
        
        &::after {
            right: 0.5rem;
        }
    }

    .clients-section {
        margin-top: 1rem;
    }
    
    .client-tags {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .action-items-section {
        border-left: none;
        border-top: 1px solid $kaizan-lightgrey;
        padding-left: 0;
        margin-top: 1.5rem;
        padding-top: 1.5rem;
    }

    .section-label-compact {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid rgba($kaizan-lightgrey, 0.5);
    }
    
    .meeting-section .section-label-compact {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }
    
    .activity-footer {
        justify-content: center;
        margin-top: 1.5rem;
        
        .btn {
            width: 100%;
            padding: 0.5rem;
        }
    }

    .row.g-2 > [class*="col-"] {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .meeting-actions {
        margin-bottom: 0.75rem;
    }
    
    .view-details-btn {
        font-size: 0.75rem;
        padding: 0.25rem 0.75rem;
    }

    .material-symbols-outlined {
        font-size: 18px;
    }
    
    .meeting-time .material-symbols-outlined {
        font-size: 16px;
    }
}

@media (max-width: 575.98px) {
    .activity-content {
        padding: 1rem;
    }

    .meeting-info {
        flex-direction: column;

        .d-flex {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .meeting-time {
        margin-bottom: 0.25rem;
    }

    .meeting-separator {
        display: none;
    }

    .meeting-title {
        margin-left: 0 !important;
    }
}