// Secondary Navigation
.secondary-nav {
    border-radius: 12px 12px 0 0;
    background-color: $kaizan-yellow-100;
    padding: 1rem;
    border-bottom: 1px solid $kaizan-yellow-200;

    .nav-pills {
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        border-radius: 100px;
        gap: 0.5rem;
        margin-bottom: 0.5rem;

        @media (max-width: 767.98px) {
            width: 100%;
            flex-direction: column;
            border-radius: 16px;
            padding: 0.5rem;
            gap: 0.375rem;
        }

        .nav-item {
            @media (max-width: 767.98px) {
                width: 100%;
            }
            
            .nav-link {
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.625rem 1.25rem;
                border-radius: 100px;
                border: none;
                background: transparent;
                color: $kaizan-darkgrey;
                font-size: 0.875rem;
                font-weight: 500;
                transition: all 0.2s ease;

                @media (max-width: 767.98px) {
                    display: flex;
                    width: 100%;
                    padding: 0.625rem 1rem;
                    border-radius: 8px;
                    justify-content: flex-start;
                }

                .material-symbols-outlined {
                    font-size: 20px;
                    
                    @media (max-width: 767.98px) {
                        font-size: 18px;
                    }
                }

                &:hover:not(.active) {
                    background-color: $kaizan-offwhite;
                    color: $kaizan-black;
                }

                &.active {
                    background-color: $kaizan-yellow;
                    color: $kaizan-black;
                }
            }
        }
    }
    
    @media (max-width: 767.98px) {
        padding: 0.75rem;
        
        &.d-flex {
            flex-direction: column;
            align-items: flex-start !important;
            
            .nav-pills {
                margin-bottom: 0.75rem;
            }
            
            .btn, .dropdown {
                align-self: flex-end;
                margin-top: 0.5rem;
            }
        }
    }
}

.secondary-nav-content {
    border-radius: 0 0 12px 12px;
    .card {
        border-radius: 12px;
    }
    .card-header {
        border-radius: 12px 12px 0 0;
    }
}

// Theme Variants
.secondary-nav {
    &.primary-theme {
        background-color: rgba($primary, 0.1);
        border-bottom-color: rgba($primary, 0.2);
        
        .nav-link.active {
            background-color: $primary;
            color: white;
        }
    }

    &.yellow-theme {
        background-color: rgba($secondary, 0.1);
        border-bottom-color: rgba($secondary, 0.2);
        
        .nav-link.active {
            background-color: $secondary;
            color: $kaizan-black;
        }
    }

    &.light-theme {
        background-color: white;
        border-bottom-color: $kaizan-lightgrey;
        
        .nav-pills {
            background-color: $kaizan-offwhite;
        }
        
        .nav-link.active {
            background-color: white;
            color: $primary;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
    }
} 

// Dark Mode Support
[data-bs-theme="dark"] {
    .secondary-nav {
        background-color: rgba($gray-800, 0.5);
        border-bottom-color: $gray-700;

        .nav-pills {
            background-color: $gray-800;

            .nav-item {
                .nav-link {
                    color: $gray-300;

                    &:hover:not(.active) {
                        background-color: $gray-700;
                        color: $white;
                    }

                    &.active {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }

        &.primary-theme {
            background-color: rgba($primary, 0.2);
            border-bottom-color: rgba($primary, 0.3);
        }

        &.yellow-theme {
            background-color: rgba($secondary, 0.2);
            border-bottom-color: rgba($secondary, 0.3);
            
            .nav-link.active {
                color: $gray-900;
            }
        }

        &.light-theme {
            background-color: $gray-800;
            border-bottom-color: $gray-700;
            
            .nav-pills {
                background-color: $gray-700;
            }
            
            .nav-link.active {
                background-color: $primary;
                color: $white;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .secondary-nav-content {
        .card {
            background-color: $gray-800;
            border-color: $gray-700;
        }
    }
}