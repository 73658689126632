.title-input {
  min-width: 20rem;
  width: 100%; // Make it take full width available to it
  height: 6rem; // Set the height to 3rem as requested
}

// Circle plus button for assigning users
.btn-circle-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #0dcaf0; // Info color to match the previous button
  font-size: 12px;
  padding: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    background-color: #0dcaf0;
    color: white;
    border-color: #0dcaf0;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.25);
  }
}

// Custom flex-wrap class for small screens only
.flex-wrap-sm-only {
  @media (max-width: 767.98px) {
    flex-wrap: wrap !important;
  }
}

// Add styles for the action title container
.action-title-container {
  flex: 1;
  min-width: 0; // Prevent flex item from overflowing
}

// Action card right side elements
.action-card-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0; // Prevent this section from shrinking
}

// User selection modal
.user-search-results {
  height: 300px;
  overflow-y: auto;
}

// Action card styling with hover effect
.action-card {
  box-shadow: none;
  border: 1px solid white;
  margin-bottom: 0;
  border-radius: 12px !important;

  .card-header {
    border: none;
    border-radius: 12px !important;
  }
  
  &:hover {
    border: 1px solid rgba(13, 202, 240, 0.8);
    transform: translateY(-2px);
  }
}

// Favorite toggle button styles
.favorite-checked {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  
  .material-symbols-outlined {
    color: white;
  }
}

.favorite-unchecked {
  background-color: white !important;
  border-color: #ffc107 !important;
  
  .material-symbols-outlined {
    color: #ffc107;
  }
}

// Complete toggle button styles
.complete-checked {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  
  .material-symbols-outlined {
    color: white;
  }
}

.complete-unchecked {
  background-color: white !important;
  border-color: #28a745 !important;
  
  .material-symbols-outlined {
    color: #28a745;
  }
}

// Delete toggle button styles
.delete-checked {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  
  .material-symbols-outlined {
    color: white;
  }
}

.delete-unchecked {
  background-color: white !important;
  border-color: #dc3545 !important;
  
  .material-symbols-outlined {
    color: #dc3545;
  }
}

// Action buttons styling
.delete-button,
.delete-toggle,
.favorite-toggle,
.complete-toggle,
.edit-button {
  width: 28px;
  height: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  
  .material-symbols-outlined {
    font-size: 16px;
  }
}