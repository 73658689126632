// Variables
$icon-size: 40px;

// Filter Group
.filter-group {
    display: flex;
    gap: 0.5rem;
    background-color: white;
    border-radius: $border-radius-pill;
    margin-bottom: 1.5rem;

    .btn {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        border-radius: $border-radius-pill;
        border: none;
        background: transparent;
        color: $kaizan-darkgrey;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s ease;

        .material-symbols-outlined {
            font-size: 20px;
        }

        &:hover:not(.active) {
            background-color: $kaizan-offwhite;
            color: $kaizan-black;
        }

        &.active {
            background-color: $kaizan-yellow;
            color: $kaizan-black;
        }
    }
}

// Search bar styling to match the design
.search-input {
    flex: 1;
    position: relative;
    margin-left: 1rem;

    .form-control {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 2.75rem;
        border: none;
        border-radius: 100px;
        background-color: white;
        font-size: 0.875rem;

        &::placeholder {
            color: $kaizan-darkgrey;
        }
    }

    .material-symbols-outlined {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $kaizan-darkgrey;
        font-size: 20px;
    }
}

// Empty States
.empty-state {
    text-align: center;
    padding: 3rem 1.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);

    &-icon {
        width: 64px;
        height: 64px;
        background: $kaizan-offwhite;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 1.5rem;

        .material-symbols-outlined {
            font-size: 32px;
            color: $kaizan-darkgrey;
        }
    }

    h5 {
        margin-bottom: 0.5rem;
        color: $kaizan-black;
    }
}

// Existing interaction styles
.interaction-item-wrapper {
    transition: all 0.2s ease;
}

.interaction-metadata {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
}


// Responsive Adjustments
@media (max-width: 768px) {

    .participants-section {
        margin: 1rem 0;

        .section-label {
            justify-content: start;
        }
    }
}

@media (max-width: 992px) {
    d.interaction-content {
        grid-template-columns: auto 1fr auto;
        gap: 1rem;

        .col-content {
            display: none; // Hide preview text on mobile
        }
    }
}

// Navigation wrapper
.interaction-nav-wrapper {
    background-color: $kaizan-yellow-100;
    padding: 1rem;
    border-bottom: 1px solid $kaizan-yellow-200;

    .interaction-nav-content {
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    // Existing filter group styles
    .filter-group {
        display: flex;
        gap: 0.5rem;
        padding: 0.75rem;
        background-color: white;
        border-radius: 100px;
        margin-bottom: 0; // Remove margin since it's handled by wrapper
    }
}

// Navigation Bar
.interaction-nav {
    background-color: $kaizan-yellow-100;
    padding: 1rem;
    border-bottom: 1px solid $kaizan-yellow-200;

    &__container {
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__filters {
        display: flex;
        gap: 0.5rem;
        padding: 0.75rem;
        background-color: white;
        border-radius: 100px;

        .btn {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            border-radius: 100px;
            border: none;
            background: transparent;
            color: $kaizan-darkgrey;
            font-size: 0.875rem;
            font-weight: 500;
            transition: all 0.2s ease;

            .material-symbols-outlined {
                font-size: 20px;
            }

            &:hover:not(.active) {
                background-color: $kaizan-offwhite;
                color: $kaizan-black;
            }

            &.active {
                background-color: $kaizan-yellow;
                color: $kaizan-black;
            }
        }
    }

    &__search {
        flex: 1;
        position: relative;
        margin-left: 1rem;

        .form-control {
            width: 100%;
            padding: 0.75rem 1rem 0.75rem 2.75rem;
            border: none;
            border-radius: 100px;
            background-color: white;
            font-size: 0.875rem;

            &::placeholder {
                color: $kaizan-darkgrey;
            }
        }

        .material-symbols-outlined {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: $kaizan-darkgrey;
            font-size: 20px;
        }
    }
}

// Interaction List
.interaction-list {
    background-color: white;
    border-radius: 12px;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

    &__date {
        padding: 1.5rem;
        font-size: 0.9375rem;
        font-weight: 600;
        color: $kaizan-black;
    }
}

// Interaction Item
.interaction {
    border-bottom: 1px solid $kaizan-lightgrey;

    &:last-child {
        border-bottom: none;
    }

    &__link {
        display: block;
        padding: 1rem;
        text-decoration: none;
        color: inherit;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: $kaizan-offwhite;
            text-decoration: none;

            .interaction__arrow {
                color: $kaizan-black;
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: auto minmax(200px, 25%) minmax(300px, 1fr) auto;
        gap: 1.5rem;
        align-items: start;
    }

    &__icon {
        width: $icon-size;
        height: $icon-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $kaizan-yellow-100;
        color: $kaizan-black
    }

    &__title {
        font-size: 0.9375rem;
        font-weight: 500;
        color: $kaizan-black;
        margin-bottom: 0.25rem;
    }

    &__participants {
        font-size: 0.8125rem;
        color: $kaizan-darkgrey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &-count {
            margin-left: 0.25rem;
        }
    }

    &__preview {
        font-size: 0.875rem;
        color: $kaizan-darkgrey;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__meta {
        display: flex;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
    }

    &__date {
        font-size: 0.8125rem;
        color: $kaizan-darkgrey;
    }

    &__arrow {
        font-size: 20px;
        color: $kaizan-midgrey;
    }
}

// Empty State
.empty-state {
    text-align: center;
    padding: 3rem 1.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);

    &__icon {
        width: 64px;
        height: 64px;
        background: $kaizan-offwhite;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 1.5rem;

        .material-symbols-outlined {
            font-size: 32px;
            color: $kaizan-darkgrey;
        }
    }
}

// Responsive
@media (max-width: 992px) {
    .interaction__content {
        grid-template-columns: auto 1fr auto;
        gap: 1rem;

        .interaction__preview {
            display: none;
        }
    }
}

// Main Container
.interactions-page {
    background-color: white;
    border-radius: 12px;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

// Navigation
.interactions-nav {
    border-radius: 12px 12px 0 0;
    background-color: $kaizan-yellow-100;
    padding: 1rem;
    border-bottom: 1px solid $kaizan-yellow-200;

    &__content {
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

// Filters
.interactions-filters {
    display: flex;
    gap: 0.5rem;
    background-color: white;
    border-radius: 100px;

    &__btn {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.625rem 1.25rem;
        border-radius: 100px;
        border: none;
        background: transparent;
        color: $kaizan-darkgrey;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s ease;

        .material-symbols-outlined {
            font-size: 20px;
        }

        &:hover:not(.active) {
            background-color: $kaizan-offwhite;
            color: $kaizan-black;
        }

        &.active {
            background-color: $kaizan-yellow;
            color: $kaizan-black;
        }
    }
}

// Search
.interactions-search {
    flex: 1;
    position: relative;
    margin-left: 1rem;

    &__input {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 2.75rem;
        border: none;
        border-radius: 100px;
        background-color: white;
        font-size: 0.875rem;

        &::placeholder {
            color: $kaizan-darkgrey;
        }
    }

    .material-symbols-outlined {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $kaizan-darkgrey;
        font-size: 20px;
    }
}

// Group of interactions by date
.interactions-group {
    &__header {
        padding: 1.5rem;
    }

    &__date {
        margin: 0;
        font-size: 0.9375rem;
        font-weight: 600;
        color: $kaizan-black;
    }
}

// Empty state
.interactions-empty {
    text-align: center;
    padding: 3rem 1.5rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);

    &__icon {
        width: 64px;
        height: 64px;
        background: $kaizan-offwhite;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 1.5rem;

        .material-symbols-outlined {
            font-size: 32px;
            color: $kaizan-darkgrey;
        }
    }

    h5 {
        margin-bottom: 0.5rem;
        color: $kaizan-black;
    }

    p {
        color: $kaizan-darkgrey;
    }
}

// Pagination
.interactions-pagination {
    padding: 1.5rem;
}

// Responsive
@media (max-width: 992px) {
    .interactions-group {
        margin: 1rem;
    }

    .interactions-nav {
        &__content {
            flex-direction: column;
            gap: 1rem;
        }
    }

    .interactions-search {
        margin-left: 0;
    }

    .interaction__content {
        grid-template-columns: auto 1fr auto;
        gap: 1rem;

        .interaction__preview {
            display: none;
        }
    }
}