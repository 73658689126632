.google-sign-in-button,
.microsoft-sign-in-button {
  display: inline-block;
  width: 187px;
  height: 44px;
  border: 0;
  
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 187px 44px;
  cursor: pointer;
}

.google-sign-in-button {
  background-image: url("../../../kaizan/static/img/people/light-mode/btn_google_signin_normal_web@2x.png");
}

.microsoft-sign-in-button {
  background-image: url("../../../kaizan/static/img/people/light-mode/ms-symbollockup_signin_light.png");
}

.turbo-progress-bar {
  height: 5px;
  background-color: $success;
}

.choices__list {
  padding-left: 5px !important;
}

.choices__list--dropdown .choices__list {
  margin: 0;
}

.choices[data-type*="select-one"]::after {
  content: "expand_more";
  font-family: "Material Symbols Outlined";
  border: none;
  position: absolute;
  right: 11.5px;
  top: 50%;
  transform: translateY(-50%);
}

.small-icon {
  font-size: 2em;
}


.text-center-full-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Makes the section fill the viewport height */
}

/* For table sort */
table [data-dir="asc"]::after {
  content: "\25B2";
}

table [data-dir="desc"]::after {
  content: "\25BC";
}

[data-sidebar-size="lg"] {
  .navbar-menu {
    overflow: scroll;
  }

  .navbar-menu::-webkit-scrollbar {
    display: none;
  }
}

.dropdown-menu {
  overflow: auto;
  max-height: 300px;
}

/* Inline Editor */
.inlineEditor {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.ProseMirror:hover {
  background: #fffff3;
}

[data-bs-theme="dark"]  {
  .ProseMirror {
    background-color: $kaizan-black;
  }
  .ProseMirror:hover {
    background: #222;
}
}
.mw-250px {
  max-width: 250px;
}

.chatbot-chat-container {
  flex-direction: column-reverse;
  max-height: 60vh;
}

.spinner-grow-tiny {
  width: 0.5rem;
  height: 0.5rem;
}

/* Work Streams */
.form-work-stream-update tr td label {
  margin-bottom: 0rem;
}

@media (max-width: 600px) {
  .work-stream-card-header, .work-stream-card-header .tier-container{
    flex-direction: column;
  }
}

/* CSAT */
.csat-success-row {
  height: 400px;
}

/* My Kaizan */
.social-container {
  min-width: fit-content;
}

.recommendation-card-danger {
  border-left: 4px solid $danger;
}

.recommendation-card-warning {
  border-left: 4px solid $warning;
}

.recommendation-card-primary {
  border-left: 4px solid $primary;
}

.email-editor {
  border: 1px solid $light;
  border-radius: $border-radius;
}

.btn-ignore-recommendation {
  margin-top: -0.2rem;
  margin-right: -0.2rem;
}

/* Messages */
@keyframes fade-out {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.messages-list {
  position: fixed;
  z-index: 1110;
  bottom: 0.6rem;
  right: 0.8rem;
  padding: 0;
  width: 20rem;
}

.message {
  position: relative;
  width: 20rem;
  padding: 1.2rem 1.6rem 0.9rem 1.6rem;
  border-radius: $border-radius;
  margin: 0.8rem 0 0;
  animation: fade-out 4s;
  list-style-type: none;
  opacity: 0;
}

@media screen and (min-width: 48em) {
  .messages-list {
    bottom: 2.4rem;
    right: 2.4rem;
  }

  .message {
    min-width: auto;
    max-width: 40rem;
    padding-top: 1.45rem;
    padding-bottom: 1.25rem;
  }
}

/* Scheduled bots */
.event-date-column {
  min-width: 10rem;
} 

.event-actions-column {
  min-width: 9rem;
}

/* Organisation settings */
.delete-cross-label {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.delete-cross-label input {
  display: none;
}

.product-card {
  margin-bottom: 0px;
}

.products-and-services-card {
  margin-right: 1rem;
}


/* Chatbots */
.card-work-stream-chat-message {
  max-width: 75%;
  word-wrap: break-word;
}

.user-work-stream-message {
  margin-left: auto;
}

.ai-work-stream-response {
  margin-right: auto;
}

.thread-messages-container, .thread-list {
  height: 55vh;
}

.thread-list {
  border: solid $light;
  border-width: 0 1px 0 0;
}

/* Navigation */
.nav-link {
  cursor: pointer;
}

.jump-to-client {
  width: 300px;
}

.navbar-menu {
  .navbar-nav {
    .menu-link {
      border-left: 4px solid transparent;
      &.active {
        border-left: 4px solid var(--#{$prefix}vertical-menu-item-active-color);
      }
    }
  }
}

.btn-bulk-assign-work-streams {
  background-color: $kaizan-yellow;
  color: $kaizan-black;
  border: none;
  text-align: left;
  margin-right: 1rem;
  &:hover {
    background-color: $kaizan-yellow;
    color: $kaizan-black;
  }
}

.transcript-scrollable {
  height: 70vh;
  overflow-y: auto;
}