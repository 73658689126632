/**
 * Speech recognition styling
 */

/* Spinning animation for the microphone button during processing */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-icon {
  animation: spin 1.5s infinite linear;
}

/* Microphone button states */
.btn-mic-recording {
  background-color: #dc3545 !important; /* Bootstrap danger color */
  border-color: #dc3545 !important;
  color: white !important;
  transition: all 0.3s ease;
}

.btn-mic-processing {
  background-color: #ffc107 !important; /* Bootstrap warning color */
  border-color: #ffc107 !important;
  color: black !important;
  transition: all 0.3s ease;
}
