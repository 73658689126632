/* TipTap Editor Styles */
.ProseMirror {
  padding: 1rem;
  border-radius: 0.25rem;
  outline: none;
  min-height: 100px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  
  > * + * {
    margin-top: 0.75em;
  }

  p {
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  
  p:empty {
    display: none;
  }
  
  p + p {
    margin-top: 0.5em;
  }
  
  ul, ol {
    padding: 0 1rem;
  }
  
  h1,h2, h3, h4, h5, h6 {
    font-size: 1.2em;
    line-height: 1.1;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
    padding: 0.25em;
    border-radius: 0.25em;
  }
  
  pre {
    background: #0D0D0D;
    color: #FFF;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    
    code {
      color: inherit;
      padding: 0;
      background: none;
    }
  }
  
  blockquote {
    padding-left: 1rem;
    border-left: 3px solid #ced4da;
    color: #6c757d;
  }
  
  hr {
    border: none;
    border-top: 2px solid #ced4da;
    margin: 2rem 0;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
}

.ProseMirror-focused {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Make TipTap editor fill the container */
.inlineEditor .ProseMirror {
  min-height: calc(25vh - 2rem);
  height: calc(100% - 2rem);
  margin-bottom: 0;
}

/* Reduce extra spacing */
.card-body .inlineEditor {
  margin-bottom: 0;
}

/* Ensure content fits properly */
.ProseMirror > *:last-child {
  margin-bottom: 0;
}

/* TipTap Bubble Menu Styles */
.bubble-menu {
  display: flex;
  background-color: #ffffff;
  padding: 0.2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  
  .bubble-menu-content {
    display: flex;
    flex-wrap: wrap;
    
    .bubble-menu-group {
      display: flex;
      border-right: 1px solid #eee;
      padding: 0 0.2rem;
      
      &:last-child {
        border-right: none;
      }
    }
    
    .bubble-menu-button {
      width: 1.75rem;
      height: 1.75rem;
      margin: 0.1rem;
      border: none;
      background: none;
      border-radius: 0.3rem;
      color: #555;
      font-size: 0.85rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      
      &:hover {
        background-color: #f1f1f1;
      }
      
      &.is-active {
        background-color: #e9ecef;
        color: #0d6efd;
      }
      
      .material-symbols-outlined {
        font-size: 1.2rem;
      }
      
      .bubble-menu-text {
        font-size: 0.9rem;
        font-weight: 600;
        font-family: monospace;
      }
      
      // Tooltip styles
      &::after {
        content: attr(title);
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0.2s;
        z-index: 1000;
      }
      
      &:hover::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/* Style for the tippy tooltip container */
.tippy-box {
  border-radius: 0.5rem;
  
  .tippy-arrow {
    color: #ffffff;
  }
}
