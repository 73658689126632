/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Main Css File
*/


//Fonts
@import "../fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-custom";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins";


// Structure
@import "../structure/topbar";
@import "../structure/page-head";
@import "../structure/footer";
@import "../structure/vertical";
@import "../structure/layouts";

// Components
@import "../components/waves";
@import "../components/avatar";
@import "../components/helper";
@import "../components/forms";
@import "../components/speech_recognition";
@import "../components/tiptap";

// Bootstrap components
@import "../components/root";
@import "../components/reboot";
@import "../components/alerts";
@import "../components/badge";
@import "../components/buttons";
@import "../components/card";
@import "../components/dropdown";
@import "../components/table";
@import "../components/modal";
@import "../components/pagination";
@import "../components/progress";
@import "../components/popover";
@import "../components/type";
@import "../components/form-control";
@import "../components/list-group";
@import "../components/accordion";

// Plugins
@import "../plugins/choices";

// Pages
@import "../pages/email";
@import "../pages/actions";

// Custom
@import "custom";
@import "activity_feed";
@import "workinteraction_detail";
@import "secondary_navigation";